/**
 * Fetured Opencourse
 * 
 * @Author: Focci
 * @Date: 2023-06-21 15:12:30
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-21 15:12:30
 */

'use client'

import PropTypes from 'prop-types'
import { LinkTo } from '@comp/LinkTo'
import { detail } from '@lib/route/event'
import { useLocale } from 'next-intl'
import AspectRatioCover from '@comp/AspectRatioCover'
import CustomCarousel from '../CustomCarousel'

function SlideRenderItem({ data }) {
  const locale = useLocale()
  
  return (
    <LinkTo href={detail(data, locale)}>
      <AspectRatioCover
        subtile
        src={data?.cover}
        width={440}
        height={280}
      />
    </LinkTo>
  )
}

export default function FeturedOpencourse({
  data = []
}) {
  return (
    <CustomCarousel
      data={data}
      SlideRender={SlideRenderItem}
    />
  )
}

FeturedOpencourse.propTypes = {
  data: PropTypes.array,
}
