/**
 * Navigation
 * 
 * @Author: Focci
 * @Date: 2023-06-17 19:22:34
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-17 19:22:34
 */

import { ButtonBase } from '@comp/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { isFunction } from 'lodash-es'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { effectCallback, getNumberOfBullets } from './lib'

export default function Navigation({
  swiper = null,
  isPrev = false,
  hideDisabledButton = false,
  children,
  ...butttonProps
}) {
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(true)
  const Icon = isPrev ? ArrowBackIcon : ArrowForwardIcon
  const disabled = isPrev ? isBeginning : isEnd

  const handleInitial = useCallback((swp) => {
    const n = getNumberOfBullets(swp)
    setIsBeginning(swp.snapIndex === 0)
    setIsEnd(swp.snapIndex === n - 1)
  }, [])

  const { navigationOnClick, ...btnProps } = butttonProps
  const handleClick = useCallback(() => {
    isPrev
      ? swiper?.slidePrev()
      : swiper?.slideNext()
    isFunction(navigationOnClick) && navigationOnClick(isPrev, swiper)
  }, [isPrev, swiper, navigationOnClick])

  useEffect(
    () => effectCallback(swiper, handleInitial),
    [handleInitial, swiper]
  )

  if(hideDisabledButton && disabled) return null

  return (
    <ButtonBase
      aria-label={isPrev ? 'Previous' : 'Next'}
      disabled={disabled}
      onClick={handleClick}
      className="shadow-md w-8 h-8 bg-black/60 text-white rounded-full"
      style={{ opacity: disabled ? 0.6 : 1 }}
      {...btnProps}
    >
      {
        children || (
          <Icon style={{ fontSize: '1rem' }} />
        )
      }
    </ButtonBase>
  )
}

Navigation.propTypes = {
  swiper: PropTypes.object,
  className: PropTypes.string,
  isPrev: PropTypes.bool,
  children: PropTypes.node,
}
