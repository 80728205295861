/**
 * 建筑汇产品卡片
 * 
 * @Author: Focci
 * @Date: 2023-06-12 15:40:31
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-12 15:40:31
 */

import PropTypes from 'prop-types'
import AspectRatioCover from '@comp/AspectRatioCover'
import Image from 'next/image'
import { LinkTo } from '@comp/LinkTo'

function ProductSpecGoodCard({
  url = '',
  cover = '',
  title = '',
  logo = '',
  meta = '',
  aspectRatioCoverProps = null
}) {
  return (
    <div className="group bg-white border rounded-b-sm">
      <LinkTo href={url} className="block">
        <div className="p-1">
          <AspectRatioCover
            hoverZoomIn
            className="object-contain"
            src={cover}
            alt={title}
            width={800}
            height={600}
            {...aspectRatioCoverProps}
          />
        </div>
        <div className="flex mt-4 p-2">
          <div className="flex-auto">
            <div className="text-f.8 line-clamp-2">
              {title}
            </div>
            {meta && (
              <div className="text-helper mt-1">
                {meta}
              </div>
            )}
          </div>
          {logo && (
            <div className="shrink-0 ml-2">
              <Image
                src={logo || ''}
                loading="lazy"
                width={70}
                height={40}
                alt={title}
                className="object-contain object-right"
                style={{ width: '3.5rem', height: '2rem' }}
              />
            </div>
          )}
        </div>
      </LinkTo>
    </div>
  )
}

ProductSpecGoodCard.propTypes = {
  cover: PropTypes.string,
  title: PropTypes.string,
  logo: PropTypes.string,
  meta: PropTypes.string,
  coverProps: PropTypes.object,
}

export default ProductSpecGoodCard
