/**
 * Search Bar
 * 
 * @Author: Focci
 * @Date: 2023-06-12 14:39:47
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-12 14:39:47
 */

'use client'

import Autocomplete from '@page/common/filter/Autocomplete'
import useAbortController from '@hook/useAbortController'
import * as routePS from '@lib/route/productspec'
import clsx from 'clsx'
import { LinkTo } from '@comp/LinkTo'
import { fetchGet, fetchProxy } from '@lib/fetch/client'
import { debounce, isArray, map } from 'lodash-es'
import { useLocale, useTranslations } from 'next-intl'
import { useState } from 'react'

const types = {
  products: { langProp: 'product', routeCallback: 'product' },
  companies: { langProp: 'provider', routeCallback: 'company' },
  cases: { langProp: 'case', routeCallback: 'caseDetail' },
}

function getList(data, t, locale) {
  const tmp = []
  map(types, (value, key) => {
    isArray(data[key]) && map(data[key], (item) => {
      tmp.push({
        ...item,
        type: t(value.langProp),
        url: routePS[value.routeCallback](
          `${encodeURIComponent(item.slug || item.label)}-${item.id}`,
          locale
        )
      })
    })
  })

  return tmp
}

function SearchBar() {
  const locale = useLocale()
  const { getSignal } = useAbortController()
  const tp = useTranslations('productspec')

  const [options, setOptions] = useState([])
  const [keyword, setKeyword] = useState('')
  const [loading, setLoading] = useState(false)

  const handleInputChange = debounce((e) => {
    const { signal } = getSignal()

    setKeyword(e.target.value)
    fetchProxy(
      fetchGet('productspec/products-autocomplete', { keyword: e.target.value }, { signal })
        .then(
          (data) => setOptions(getList(data, tp, locale))
        ),
      setLoading
    )
  }, 500)

  return (
    <div className="py-2 flex items-center max-w-full">
      <div className="w-160 max-w-full">
        <Autocomplete
          // freeSolo
          loading={loading}
          options={options.sort((a, b) => a.type - b.type)}
          onInputChange={handleInputChange}
          placeholder={tp('productSearchPlaceholder')}
          renderOption={(props, option) => (
            <LinkTo 
              key={option.id}
              href={option.url}
              className={clsx(['text-f.7', props.className])}
            >
              {option.label}
            </LinkTo>
          )}
          open={!!keyword}
          groupBy={(option) => option.type}
        />
      </div>
    </div>
  )
}

// SearchBar.propTypes = {
//   className: PropTypes.string,
// }

export default SearchBar
