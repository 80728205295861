import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/AspectRatioCover.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/productspec/home/case/FeaturedCases.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/productspec/home/Feature.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/productspec/home/navbar/NavbarMenu.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/productspec/home/NavBarAndPromotion.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/productspec/home/opencouses/FeturedOpencourse.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/productspec/home/products/ProductLists.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/productspec/SearchBar.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/public/imgs/translate.svg");
