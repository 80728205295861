/**
 * PropertyIcons(房间数、浴室数、车位)
 * 
 * @Author: Focci
 * @Date: 2023-07-19 15:28:32
 * @Last Modified by: Focci
 * @Last Modified time: 2023-07-19 15:28:32
 */

import PropTypes from 'prop-types'
import SVGBedroom from '@img/bedroom.svg'
import SVGBathroom from '@img/bathroom.svg'
import SVGCarspace from '@img/carspace.svg'
import SVGFloorArea from '@img/floor-area.svg'
import SVGLandArea from '@img/land-area.svg'
import clsx from 'clsx'
import { Tooltip } from '@comp/material'
import { useTranslations } from 'next-intl'
import { keys } from 'lodash-es'

const icons = {
  bedrooms: { icon: SVGBedroom, tooltip: 'bedroomsCount' },
  bathrooms: { icon: SVGBathroom, tooltip: 'bathroomsCount' },
  carspaces: { icon: SVGCarspace, tooltip: 'carspacesCount' },
  floorarea: { icon: SVGFloorArea, tooltip: 'buildingArea' },
  landarea: { icon: SVGLandArea, tooltip: 'landArea' },
}

function getOptions({ data, tp, th }) {
  const tmp = []

  keys(icons).forEach((key) => {
    if(!!data[key] && data[key] !== '0') {
      const special = ['floorarea', 'landarea'].indexOf(key) > -1

      tmp.push({
        icon: icons[key].icon,
        value: special ? `${data[key]}m²` : data[key],
        tooltip: special
          ? `${th(icons[key].tooltip)} ${data[key]}m²`
          : tp(icons[key].tooltip, { n: data[key] })
      })
    }
  })

  return tmp
}

export default function PropertyIcons({
  data = {},
  iconWidth = '.9rem',
  className = 'gap-2',
  children
}) {
  const tp = useTranslations('profile')
  const th = useTranslations('house')

  const options = getOptions({ data, tp, th })
  const tooltips = options.map((item) => item.tooltip).join(', ')

  return options.length > 0 && (
    <Tooltip placement="top-start" title={tooltips} arrow={false}>
      <div 
        className={clsx([
          'flex items-center mt-0.5 overflow-hidden',
          className
        ])}
      >
        {options.map((item, index) => {
          const Icon = item.icon
          return (
            <div key={index + item.tooltip} className="flex items-center leading-none">
              <Icon width={iconWidth} />
              <span className="ml-1">{item.value}</span>
            </div>
          )
        })}
        {children}
      </div>
    </Tooltip>
  )
}

PropertyIcons.propTypes = {
  data: PropTypes.object,
  iconWidth: PropTypes.string,
}
