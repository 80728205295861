/**
 * Section
 * 
 * @Author: Focci
 * @Date: 2023-06-24 21:31:14
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-24 21:31:14
 */

import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslations } from 'next-intl'
import { LinkTo } from '@comp/LinkTo'

export function MoreLink({
  label = '',
  link = '',
  className = ''
}) {
  const tc = useTranslations('common')
  return (
    <LinkTo 
      href={link} 
      className={clsx([
        'py-1 px-3 border inline-block rounded-sm whitespace-nowrap',
        className
      ])}
    >
      {label || tc('viewMore')}
    </LinkTo>
  )
}

MoreLink.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
}

export default function Section({
  title = null,
  className = 'mt-8 md:mt-16',
  titleClassName = 'text-f1.4 font-medium',
  TitleTag = 'h2',
  action = null,
  children
}) {
  return (
    <section className={className}>
      <div className="grid grid-cols-10 items-center justify-between mb-6">
        <TitleTag className={clsx(['col-span-8', titleClassName])}>
          {title}
        </TitleTag>
        <div className="ml-4 col-span-2 flex justify-end">
          {action}
        </div>
      </div>
      {children}
    </section>
  )
}

Section.propTypes = {
  title: PropTypes.node,
  titleClassName: PropTypes.string,
  TitleTag: PropTypes.string,
  action: PropTypes.node,
  children: PropTypes.node,
}
